import React from "react";
import "./styles.css";

export const Application = ({ application }) => {
  function formatDate(isoString) {
    const date = new Date(isoString);

    // Опции для форматирования даты
    const options = {
      year: "numeric",
      month: "short", // Используйте 'short' для сокращенного названия месяца
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Локализованное форматирование даты
    return date.toLocaleDateString("ru-RU", options);
  }

  return (
    <div className="application">
      <div className="application_container">
        <div>
          <div className="application_textBlock">
            <span className="application_text">Итоговая сумма: </span>
            <span className="application_text1">{application.amount}</span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">Сколько чел отдал: </span>
            <span className="application_text1">
              {application.amountMoneyCurrency}
            </span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">
              Валюта которую отдает чел:{" "}
            </span>
            <span className="application_text1">{application.bankTicket}</span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">Номер карты: </span>
            <span className="application_text1">{application.cardNumber}</span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">Курс: </span>
            <span className="application_text1">{application.course}</span>
          </div>
        </div>
        <div>
          <div className="application_textBlock">
            <span className="application_text">Дата создания заявки: </span>
            <span className="application_text1">
              {formatDate(application?.creationDateTime)}
            </span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">ФИО: </span>
            <span className="application_text1">{application.credentials}</span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">Количество крипты </span>
            <span className="application_text1">
              {application.cryptoAmount}
            </span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">Название крипты: </span>
            <span className="application_text1">
              {application.cryptoTicket}
            </span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">E-Mail: </span>
            <span className="application_text1">{application.email}</span>
          </div>
        </div>
        <div>
          <div className="application_textBlock">
            <span className="application_text">Заявка в работе: </span>
            <span className="application_text1">{application.email}</span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">id заявки: </span>
            <span className="application_text1">{application.payoutId}</span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">Телефон СБП: </span>
            <span className="application_text1">
              {application.phoneNumberSbp}
            </span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">Дата обновления: </span>
            <span className="application_text1">
              {formatDate(application?.updateDateTime)}
            </span>
          </div>
          <div className="application_textBlock">
            <span className="application_text">Адрес кошелька: </span>
            <span className="application_text1">
              {application.walletAddress}
            </span>
          </div>
        </div>

        <div className="application_info">{application.payoutStatus}</div>
      </div>
      <div className="application_buttonBlock">
        <div className="application_childBtnBlock w100">
          <div className="button success">Выполнена</div>
          <div className="button delete">Удалить</div>
          <div className="button error">Ошибка</div>
        </div>
        <div className="w100 dflex">
          <div className="button give">Отдать заявку</div>
        </div>
        <div className="w100"></div>
      </div>
    </div>
  );
};
