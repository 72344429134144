import React, { useEffect, useState } from "react";
import "./styles.css";
import { Application } from "../../Application/Application";
import { getApplications } from "../../../../api/admin/applications";

export const Applications = ({ params }) => {
  const [application, setApplication] = useState([]);
  const fetchApplications = async () => {
    try {
      const data = await getApplications();
      setApplication(data);
    } catch (error) {
      console.error("Error fetching data in useEffect:", error);
    }
  };
  useEffect(() => {
    fetchApplications();
  }, []);

  return (
    <div className="adminRight_Block">
      {application.map((app) => (
        <Application application={app} />
      ))}
    </div>
  );
};
