import React from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import { AccountMenu } from "./AccountMenu/AccountMenu";
import { Orders } from "./Orders/Orders";
import { Settings } from "./Settings/Settings";

export const Account = () => {
  const params = useParams();
  console.log(params);
  return (
    <div className="account_container">
      <div className="account_textHeader">Личный Кабинет</div>
      <div className="account_main">
        <AccountMenu />
        {params.name === "orders" && <Orders />}
        {params.name === "settings" && <Settings />}
      </div>
    </div>
  );
};
