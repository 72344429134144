import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  deletePaymentLine,
  getPaymentAllList,
  getPaymentList,
  newPaymetsList,
  updatePaymentLine,
} from "../../../api/admin/paymentsList";
import { toast } from "react-toastify";

export const PaymentList = () => {
  const [currencyCodeList, setCurrencyCodeList] = useState([]);
  const [currencyStatusList, setCurrencyStatusList] = useState([]);
  const [paymentSystemList, setPaymentSystemList] = useState([]);

  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null);
  const [selectedCurrencyStatus, setSelectedCurrencyStatus] = useState(null);
  const [selectedPaymentSystem, setSelectedPaymentSystem] = useState(null);

  const [allPaymentsList, setAllPaymentsList] = useState([]);

  const [inputValues, setInputValues] = useState([]);

  useEffect(() => {
    setInputValues(
      allPaymentsList.map((payment) => ({
        reserve: payment?.reserve || "",
        minAmount: payment?.minAmount || "",
        maxAmount: payment?.maxAmount || "",
        requisites: payment?.requisites || "",
      }))
    );
  }, [allPaymentsList]);

  const handleInputChange = (index, field, value) => {
    const newInputValues = [...inputValues];
    const originalValue = allPaymentsList[index][field];

    if (value === originalValue) {
      return;
    }

    newInputValues[index] = {
      ...newInputValues[index],
      [field]: value,
    };
    setInputValues(newInputValues);
  };

  const handleSave = (
    index,
    id,
    paymentSystemId,
    currencyCode,
    currencyCodeId,
    status
  ) => {
    const dataToSave = {
      ...inputValues[index],
      paymentSystemId,
      currencyCode,
      currencyCodeId,
      status,
    };

    updatePaymentLine(id, dataToSave).then(() => {
      toast("Успешно");
    });
  };

  const handleDelete = (id) => {
    deletePaymentLine(id).then(() => {
      toast("Успешно");
      fetchPaymentsAllList();
    });
  };

  const newPayment = () => {
    const data = {
      status: selectedCurrencyStatus,
      paymentSystemId: selectedPaymentSystem,
      currencyCodeId: selectedCurrencyCode,
    };
    if (
      selectedCurrencyStatus &&
      selectedPaymentSystem &&
      selectedCurrencyCode
    ) {
      newPaymetsList(data)
        .then(() => {
          toast("Успешно");
          fetchPaymentsAllList();
        })
        .catch((err) => {
          toast.error(`Ошибка: ${err}`);
        });
    } else {
      toast.error("Выберите все опции");
    }
  };

  const handleCurrencyStatusChange = (event) => {
    setSelectedCurrencyStatus(event.target.value);
  };

  const handlePaymentSystemChange = (event) => {
    const selectedItem = paymentSystemList.find(
      (item) => item.paymentName === event.target.value
    );
    setSelectedPaymentSystem(selectedItem?.id);
  };
  const fetchPaymentsList = async () => {
    try {
      const data = await getPaymentList();
      setCurrencyCodeList(data?.currencyCodeList || []);
      setCurrencyStatusList(data?.currencyStatusList || []);
      setPaymentSystemList(data?.paymentSystemList || []);
    } catch (error) {
      console.error("Error fetching data in useEffect:", error);
    }
  };

  const fetchPaymentsAllList = async () => {
    try {
      const data = await getPaymentAllList();
      setAllPaymentsList(data);
    } catch (error) {
      console.error("Error fetching data in useEffect:", error);
    }
  };

  useEffect(() => {
    fetchPaymentsList();
    fetchPaymentsAllList();
  }, []);
  const handleCurrencyCodeChange = (event) => {
    const selectedItem = currencyCodeList.find(
      (item) => item.currencyCode === event.target.value
    );
    setSelectedCurrencyCode(selectedItem ? selectedItem.id : null);
  };
  console.log(allPaymentsList);
  return (
    <div className="adminRight_Block">
      <div className="payment_menu__Block">
        <select className="select" onChange={handleCurrencyCodeChange}>
          <option value="" disabled selected>
            Выберите код
          </option>
          {currencyCodeList.map((item) => (
            <option key={item.id} value={item.currencyCode}>
              {item.currencyCode}
            </option>
          ))}
        </select>

        <select className="select" onChange={handleCurrencyStatusChange}>
          <option value="" disabled selected>
            Выберите статус
          </option>
          {currencyStatusList.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>

        <select className="select" onChange={handlePaymentSystemChange}>
          <option value="" disabled selected>
            Выберите ПС
          </option>
          {paymentSystemList?.map((item) => (
            <option key={item.id} value={item.paymentName}>
              {item.paymentName}
            </option>
          ))}
        </select>
        <div className="payments_button" onClick={newPayment}>
          Добавить
        </div>
      </div>
      <div className="payments_systemContainer">
        <div className="payments_Pay">Валюта</div>
        <div className="payments_Pay">Код валюты</div>
        <div className="payments_Pay">XML</div>
        <div className="payments_Pay">Резерв</div>
        <div className="payments_Pay">Статус</div>
        <div className="payments_Pay">Минимальная сумма</div>
        <div className="payments_Pay">Максимальная сумма</div>
        <div className="payments_Pay">Реквизиты</div>
        <div className="payments_Pay">-</div>
        <div className="payments_Pay">-</div>
      </div>
      {allPaymentsList.map((codes, index) => (
        <div key={codes.id || index} className="payments_systemContainer">
          <div className="payments_Pay">
            {codes?.paymentSystem?.paymentName || "Отсутствует"}
          </div>
          <div className="payments_Pay">
            {codes?.currencyCode?.currencyCode || "Отсутствует"}
          </div>
          <div className="payments_Pay">
            {codes?.currencyCode?.symbol || "-"}
          </div>
          <div className="payments_Pay">
            <input
              className="payments_input"
              value={inputValues[index]?.reserve || ""}
              onChange={(e) =>
                handleInputChange(index, "reserve", e.target.value)
              }
            />
          </div>
          <div className="payments_Pay">
            {codes?.actualFlg || "Отсутствует"}
          </div>
          <div className="payments_Pay">
            <input
              className="payments_input"
              value={inputValues[index]?.minAmount || ""}
              onChange={(e) =>
                handleInputChange(index, "minAmount", e.target.value)
              }
            />
          </div>
          <div className="payments_Pay">
            <input
              className="payments_input"
              value={inputValues[index]?.maxAmount || ""}
              onChange={(e) =>
                handleInputChange(index, "maxAmount", e.target.value)
              }
            />
          </div>
          <div className="payments_Pay">
            <input
              className="payments_input"
              value={inputValues[index]?.requisites || ""}
              onChange={(e) =>
                handleInputChange(index, "requisites", e.target.value)
              }
            />
          </div>
          <div
            className="payments_PayButton"
            onClick={() =>
              handleSave(
                index,
                codes?.id,
                codes?.paymentSystem?.id,
                codes?.currencyCode?.currencyCode,
                codes?.currencyCode?.id,
                codes?.actualFlg
              )
            }
          >
            сохранить
          </div>
          <div
            className="payments_PayButtonDelete"
            onClick={() => handleDelete(codes?.id)}
          >
            удалить
          </div>
        </div>
      ))}
    </div>
  );
};
