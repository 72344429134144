import React from "react";
import { Details } from "../components/Details/Details";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";

export const DetailsPage = () => {
  return (
    <>
      <Header />
      <Details />
      <Footer />
    </>
  );
};
