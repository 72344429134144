import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { Loader } from "./childrenUi/Loader/Loader";
import { Modal } from "../../UI/Modal/Modal";
import { Context } from "../../../App";
import { makeExchange } from "../../../api/makeExchange";
import { getAllDirections } from "../../../api/admin/exchangeDirection";
import { ReactComponent as Close } from "../../../images/svgs/close.svg";

const ExchangeInput = ({
  label,
  value,
  onChange,
  readOnly,
  selectText,
  onSelectClick,
  image,
}) => (
  <div className="exchange_inputContainer">
    <div className="exchange_textUp">{label}</div>
    <div className="exchange_inputBlock">
      <input
        className="exchange_input"
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
      <div className="exchange_select" onClick={onSelectClick}>
        <img
          src={`data:image/png;base64,${image}`}
          alt="Base64 изображение"
          className="payments_image"
        />
        <div className="exchange_SelectText">{selectText}</div>
      </div>
    </div>
  </div>
);

const ExchangeRate = ({ isLoading, rate }) => (
  <div className="exchange_courseBlock">
    <div className="exchange_courseLeftBlock">
      <Loader />
      <span className="exchange_courseText">Курс</span>
    </div>
    <div className="exchange_courseRightBlock">
      <span className="exchange_course">{rate}</span>
    </div>
  </div>
);

const PromoInput = () => (
  <div className="promo_container">
    <input className="promo_input" placeholder="Промокод, необязательно" />
    <span className="why_promo">Как получить промокод?</span>
  </div>
);

const ExchangeButton = ({ onClick }) => (
  <div className="exchange_button" onClick={onClick}>
    <span className="exchange_buttonText">Перейти к вводу реквизитов</span>
  </div>
);

const ExchangeModal = ({
  active,
  setActive,
  modalType,
  allDirections,
  selectedCurrency,
  selectCurrency,
  selectExchange,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Функция для обновления значения поиска
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Фильтрация валют на основе значения поиска
  const filteredCurrencies = allDirections.filter((currency) =>
    currency.paymentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Фильтрация обменов на основе значения поиска
  const filteredExchanges = selectedCurrency?.exchanges.filter((exchange) =>
    exchange.currencyReturn.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <Modal active={active} setActive={setActive}>
      <div className="exchangeModal_container">
        <div className="exchange_Close" onClick={() => setActive(false)}>
          <Close />
        </div>
        <div className="exchangeModal_headerBlock">
          <div className="exchangeModal_selectText">
            {modalType === "currency" ? "Выберите валюту" : "Выберите обмен"}
          </div>
          <div className="exhcnageModal_InputSearchBlock">
            <input
              className="exhcnageModal_InputSearch"
              placeholder="Поиск по названию"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="exchangeModal_MainBlock">
          {modalType === "currency"
            ? filteredCurrencies.map((currency, index) => (
                <div
                  key={index}
                  className="exchangeModal_selectVallet"
                  onClick={() => selectCurrency(currency)}
                >
                  <img
                    src={`data:image/png;base64,${currency.image}`}
                    alt="Base64 изображение"
                    className="payments_image"
                  />
                  {currency.paymentName} {currency.currencyCode}
                </div>
              ))
            : filteredExchanges.map((exchange, index) => (
                <div
                  key={index}
                  className="exchangeModal_selectVallet"
                  onClick={() => selectExchange(exchange)}
                >
                  <img
                    src={`data:image/png;base64,${exchange.currencyReturnImage}`}
                    alt="Base64 изображение"
                    className="payments_image"
                  />
                  {exchange.currencyReturn}
                </div>
              ))}
        </div>
      </div>
    </Modal>
  );
};

export const Exchange = () => {
  const navigate = useNavigate();
  const { setSelectedExchange, setAmount, amount } = useContext(Context);

  const [isScrolled, setIsScrolled] = useState(false);
  const [allDirections, setAllDirections] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const [modalType, setModalType] = useState("currency");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedExchangeLocal, setSelectedExchangeLocal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [giveValue, setGiveValue] = useState("500");
  const [receiveValue, setReceiveValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await makeExchange();
        const directions = await getAllDirections();
        setAllDirections(directions);

        if (directions.length > 0) {
          const rubDirection =
            directions.find((dir) => dir.currencyCode === "RUB") ||
            directions[0];
          const btcExchange =
            rubDirection.exchanges.find((ex) =>
              ex.currencyReturn.includes("BTC")
            ) || rubDirection.exchanges[0];

          setSelectedCurrency(rubDirection);
          setSelectedExchangeLocal(btcExchange);
          setSelectedExchange(btcExchange);
          setAmount(500);

          // Рассчитываем начальное значение для поля "Получаете"
          const initialReceiveValue = (500 * btcExchange.courseNumber).toFixed(
            8
          );
          setReceiveValue(formatNumber(initialReceiveValue.toString()));

          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchData();
  }, [setSelectedExchange, setAmount]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 140);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, [selectedExchangeLocal]);

  const formatNumber = (value) => {
    let inputValue = value.replace(/[^0-9.]/g, "");
    const parts = inputValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  };

  const handleGiveChange = (e) => {
    const formatted = formatNumber(e.target.value);
    setGiveValue(formatted);
    if (selectedExchangeLocal) {
      const numericValue = parseFloat(formatted.replace(/ /g, "")) || 0;
      setAmount(numericValue);
      setReceiveValue(
        formatNumber(
          (numericValue * selectedExchangeLocal.courseNumber).toString()
        )
      );
    }
  };

  const handleReceiveChange = (e) => {
    const formatted = formatNumber(e.target.value);
    setReceiveValue(formatted);
    if (selectedExchangeLocal) {
      const numericValue = parseFloat(formatted.replace(/ /g, "")) || 0;
      const calculatedGive = numericValue / selectedExchangeLocal.courseNumber;
      setGiveValue(formatNumber(calculatedGive.toString()));
      setAmount(calculatedGive);
    }
  };

  const selectCurrency = (currency) => {
    setSelectedCurrency(currency);
    const btcExchange =
      currency.exchanges.find((ex) => ex.currencyReturn.includes("BTC")) ||
      currency.exchanges[0];
    setSelectedExchangeLocal(btcExchange);
    setSelectedExchange(btcExchange);
    setActiveModal(false);
    // Пересчитываем значения при смене валюты
    const numericGive = parseFloat(giveValue.replace(/ /g, "")) || 0;
    setReceiveValue(
      formatNumber((numericGive * btcExchange.courseNumber).toString())
    );
  };

  const selectExchange = (exchange) => {
    setSelectedExchangeLocal(exchange);
    setSelectedExchange(exchange);
    setActiveModal(false);
    // Пересчитываем значения при смене обмена
    const numericGive = parseFloat(giveValue.replace(/ /g, "")) || 0;
    setReceiveValue(
      formatNumber((numericGive * exchange.courseNumber).toString())
    );
  };

  const openModal = (type) => {
    setModalType(type);
    setActiveModal(true);
  };
  const formatExchangeRate = () => {
    if (selectedExchangeLocal) {
      return `1 ${
        selectedCurrency?.currencyCode
      } = ${selectedExchangeLocal.courseNumber.toFixed(
        9
      )} ${selectedExchangeLocal.currencyReturn.split(" ").pop()}`;
    }
    return "Выберите обмен";
  };

  function getStringAfterSpace(inputString) {
    const parts = inputString.split(" ");
    return parts.length > 1 ? parts[1] : "";
  }

  return (
    <>
      <div className={`exchange_container ${isScrolled ? "scrolled" : ""}`}>
        {!loading ? (
          <>
            <ExchangeInput
              label="Отдаете"
              value={giveValue}
              onChange={handleGiveChange}
              selectText={
                selectedCurrency
                  ? `${selectedCurrency.currencyCode}`
                  : "Выберите валюту"
              }
              image={selectedCurrency?.image}
              onSelectClick={() => openModal("currency")}
            />
            <ExchangeInput
              label="Получаете"
              value={receiveValue}
              onChange={handleReceiveChange}
              selectText={
                selectedExchangeLocal
                  ? getStringAfterSpace(selectedExchangeLocal.currencyReturn)
                  : "Выберите обмен"
              }
              image={selectedExchangeLocal?.currencyReturnImage}
              onSelectClick={() => openModal("exchange")}
            />
            <ExchangeRate isLoading={isLoading} rate={formatExchangeRate()} />
            <PromoInput />
            <ExchangeButton onClick={() => navigate("/details")} />
            <ExchangeModal
              active={activeModal}
              setActive={setActiveModal}
              modalType={modalType}
              allDirections={allDirections}
              selectedCurrency={selectedCurrency}
              selectCurrency={selectCurrency}
              selectExchange={selectExchange}
            />
          </>
        ) : (
          <div className="loader_container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </>
  );
};
