import React from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
export const RightAdminMenu = () => {
  const navigate = useNavigate();
  return (
    <div className="adminHeader_Links_Block">
      <div className="adminHeader_Padding">
        <div className="adminHeader_Links" onClick={() => navigate("/admin/1")}>
          Платежные системы
        </div>
        <div className="adminHeader_Links" onClick={() => navigate("/admin/2")}>
          Коды валют
        </div>
        <div className="adminHeader_Links" onClick={() => navigate("/admin/3")}>
          Список валют
        </div>
        <div className="adminHeader_Links" onClick={() => navigate("/admin/4")}>
          Курсы из источников
        </div>
        <div className="adminHeader_Links" onClick={() => navigate("/admin/5")}>
          Направление обмена
        </div>
        <div className="adminHeader_Links" onClick={() => navigate("/admin/6")}>
          Заявки
        </div>
      </div>
    </div>
  );
};
