import React, { useEffect, useState } from "react";
import "./styles.css";
import { Modal } from "../UI/Modal/Modal";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon from "../../images/Upscaled (Subtle) (2048x2048) (1).png";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Burger } from "../../images/svgs/burger.svg";
import axios from "axios";
import { getAccount } from "../../api/account";
import { useAccount } from "../AccountProvider";

export const Header = () => {
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(false);
  const notify = () => toast("Вы ввели неверные данные");
  const [reg, setReg] = useState(false);
  const [password, setPassword] = useState(null);
  const [password1, setPassword1] = useState(null);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const token = localStorage.getItem("AccessToken");
  const account = useAccount();

  const [burgerActive, setBurgerActive] = useState(false);

  const Auth = () => {
    if (!reg) {
      const data = {
        email: email,
        password: password,
      };
      if (password && email) {
        axios
          .post(`https://server.kykyshka.pro/api/v1/auth/authenticate`, data)
          .then((res) => {
            toast("Вы успешно вошли в аккаунт");
            localStorage.setItem("AccessToken", res.data.access_token);
            setActiveModal(false);
            getAccount(res.data.access_token);
            window.location.reload();
          })
          .catch((err) => toast.error("Ошибка", err.message));
      } else {
        toast.error("Введите данные от аккаунта");
      }
    } else {
      const data = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
      };
      if (password && email && password1 && firstName && lastName) {
        if (password === password1) {
          axios
            .post(`https://server.kykyshka.pro/api/v1/auth/register`, data)
            .then((res) => {
              toast("Вы успешно зарегистрировались");
              localStorage.setItem("AccessToken", res.data.access_token);
              setActiveModal(false);
            })
            .catch((err) => toast.error("Ошибка", err.message));
        } else {
          toast.error("Пароли не совпадают");
        }
      } else {
        toast.error("Введите все данные");
      }
    }
  };
  function getInitialWithDot(name) {
    if (typeof name !== "string" || name.length === 0) {
      return ""; // Возвращаем пустую строку, если входное значение не является строкой или пусто
    }

    return name.charAt(0) + ".";
  }

  const handleMobileClick = (link) => {
    setBurgerActive(false);
    navigate(`/${link}`);
  };
  return (
    <>
      {account?.role === "ADMIN" && (
        <div className="header_adminBlock" onClick={() => navigate("/admin/1")}>
          Войти в админ-панель
        </div>
      )}
      <div className="header_container">
        <div className="header_link1">
          <img
            src={Icon}
            className="header_img"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="header_link">
          <div className="link_text" onClick={() => navigate("/rules")}>
            Правила обмена
          </div>
          <div className="link_text" onClick={() => navigate("/partners")}>
            Партнерам
          </div>
          <div className="link_text" onClick={() => navigate("/aml")}>
            AML / KYC
          </div>
          <div className="link_text" onClick={() => navigate("/faq")}>
            FAQ
          </div>
          <div className="link_text" onClick={() => navigate("/contacts")}>
            Контакты
          </div>
        </div>
        <div className="header_link">
          <div className="link_text">
            <div class="background"></div>
          </div>
          <div
            className="link_auth"
            onClick={() => {
              {
                !account?.firstname.length
                  ? setActiveModal(!activeModal)
                  : navigate("/account/orders");
              }
            }}
          >
            {account?.firstname.length > 0
              ? `${account.firstname} ${getInitialWithDot(
                  account.lastname || ""
                )}`
              : "Войти"}
          </div>
        </div>
        <Modal active={activeModal} setActive={setActiveModal}>
          <div className="auth_container">
            {reg ? (
              <>
                <div className="auth_text">Регистрация</div>
                <div className="auth_inputBlock">
                  <input
                    className="auth_input"
                    placeholder="Имя"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="auth_inputBlock">
                  <input
                    className="auth_input"
                    placeholder="Фамилия"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="auth_inputBlock">
                  <input
                    className="auth_input"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="auth_inputBlock">
                  <input
                    className="auth_input"
                    placeholder="Пароль"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="auth_inputBlock">
                  <input
                    className="auth_input"
                    placeholder="Повторите пароль"
                    type="password"
                    onChange={(e) => setPassword1(e.target.value)}
                  />
                </div>
                <button className="auth_button" onClick={Auth}>
                  Регистрация
                </button>
                <div className="auth_bottom">
                  <div>У вас уже есть аккаунт?</div>
                  <button
                    className="auth_buttonReg"
                    onClick={() => {
                      setReg(false);
                    }}
                  >
                    Войти
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="auth_text">Вход в личный кабинет</div>
                <div className="auth_inputBlock">
                  <input
                    className="auth_input"
                    placeholder="Логин"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="auth_inputBlock">
                  <input
                    className="auth_input"
                    placeholder="Пароль"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="auth_infoBlock">
                  <div className="auth_sendMe container">
                    Запомнить меня
                    <input className="styled-input-single" type="checkbox" />
                  </div>
                  <div>Забыли пароль?</div>
                </div>
                <div className="auth_button" onClick={Auth}>
                  Войти
                </div>
                <div className="auth_bottom">
                  <div>У вас еще нет аккаунта?</div>
                  <div className="auth_buttonReg" onClick={() => setReg(true)}>
                    Создать аккаунт
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
        <div
          className="header_burgerMenuBlock"
          onClick={() => setBurgerActive(true)}
        >
          <Burger />
        </div>
      </div>
      {burgerActive && (
        <div className="header_burgerMenu">
          <div className="header_menuBlockBurger">
            <div
              className="link_auth"
              onClick={() => {
                {
                  setBurgerActive(!burgerActive);
                  !account?.firstname.length && setActiveModal(!activeModal);
                }
              }}
            >
              {account?.firstname.length > 0
                ? `${account.firstname} ${getInitialWithDot(
                    account.lastname || ""
                  )}`
                : "Войти"}
            </div>

            <div className="header_menuBlockBurgerMain">
              <div
                className="link_headerText"
                onClick={() => handleMobileClick("rules")}
              >
                Правила обмена
              </div>
              <div
                className="link_headerText"
                onClick={() => handleMobileClick("partners")}
              >
                Партнерам
              </div>
              <div
                className="link_headerText"
                onClick={() => handleMobileClick("aml")}
              >
                AML / KYC
              </div>
              <div
                className="link_headerText"
                onClick={() => handleMobileClick("faq")}
              >
                FAQ
              </div>
              <div
                className="link_headerText"
                onClick={() => handleMobileClick("contacts")}
              >
                Контакты
              </div>
              <div
                className="link_headerTextExit"
                onClick={() => setBurgerActive(!burgerActive)}
              >
                X
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
