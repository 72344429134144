import React from "react";
import { Header } from "../components/Header/Header";
import { Rules } from "../components/Rules/Rules";
import { Partners } from "../components/Partners/Partners";
import { Footer } from "../components/Footer/Footer";

export const PartnersPage = () => {
  return (
    <>
      <Header />
      <Partners />
      <Footer />
    </>
  );
};
