import React, { useEffect, useState } from "react";
import "./styles.css";
import { getPayments, newPayment } from "../../../api/admin/payments";

export const PaymentsSys = () => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [payments, setPayments] = useState([]);
  const [byteArray, setByteArray] = useState(null);

  const fetchPayments = async () => {
    try {
      const data = await getPayments();
      setPayments(data);
    } catch (error) {
      console.error("Error fetching data in useEffect:", error);
    }
  };

  const fileToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        resolve(Array.from(uint8Array));
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const bytes = await fileToByteArray(file);
        setByteArray(bytes);
        console.log("Файл преобразован в массив байтов:", bytes);
        // Здесь вы можете отправить bytes на сервер
      } catch (error) {
        console.error("Ошибка при преобразовании файла:", error);
      }
    }
  };

  function displayBase64Image(base64String, imgElementId) {
    // Получаем элемент img по его ID
    const imgElement = document.getElementById(imgElementId);

    if (!imgElement) {
      console.error(`Элемент с ID '${imgElementId}' не найден.`);
      return;
    }

    // Устанавливаем атрибут src элемента img на строку Base64
    imgElement.src = `data:image/png;base64,${base64String}`;
  }

  const addPayment = () => {
    try {
      const data = {
        paymentSystemName: name,
        image: byteArray,
      };
      console.log(byteArray);
      newPayment(data).then(() => fetchPayments());
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  return (
    <div className="adminRight_Block">
      <div className="payments_container">
        <div>Платежные системы</div>
        <div className="payments_block">
          <div className="">
            <span>Название пс</span>
            <input
              placeholder="Название пс"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <span>Логотип</span>
            <input type="file" onChange={handleFileChange} />
          </div>
          <div className="payments_button" onClick={addPayment}>
            Добавить пс
          </div>
        </div>

        <div className="payments_systemContainer">
          <div className="payments_Pay">Логотип</div>
          <div className="payments_Pay">Название ПС</div>
          <div className="payments_Pay">Привязанные валюты</div>
        </div>
        {payments.map((payment) => (
          <div className="payments_systemContainer">
            <div className="payments_Pay">
              <img
                src={`data:image/png;base64,${payment.image}`}
                alt="Base64 изображение"
                className="payments_image"
              />
            </div>
            <div className="payments_Pay">
              {payment.paymentName || "Отсутствует"}
            </div>
            <div className="payments_Pay">
              {payment.currency?.paymentName || "Отсутствует"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
