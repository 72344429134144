import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import "./index.css";
import { ToastContainer } from "react-toastify";
import { createContext, useState } from "react";
import AccountProvider from "./components/AccountProvider";

export const Context = createContext(null);

function App() {
  const [selectedExchange, setSelectedExchange] = useState(null);
  const [amount, setAmount] = useState(0);
  const [step, setStep] = useState(false);
  const [uuidResult, setUuidResult] = useState([]);
  return (
    <Context.Provider
      value={{
        selectedExchange,
        setSelectedExchange,
        setAmount,
        amount,
        setStep,
        step,
        setUuidResult,
        uuidResult,
      }}
    >
      <AccountProvider>
        <BrowserRouter>
          <div className="App">
            <AppRouter />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            limit={10}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </BrowserRouter>
      </AccountProvider>
    </Context.Provider>
  );
}

export default App;
