import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { DetailsPage } from "./pages/DetailsPage";
import Admin from "./components/Admin";
import { RulesPage } from "./pages/RulesPage";
import { PartnersPage } from "./pages/PartnersPage";
import { AmlPage } from "./pages/AmlPage";
import { FaqPage } from "./pages/FaqPage";
import { ContactPage } from "./pages/ContactsPage";
import { AccountPage } from "./pages/AccountPage";
import { ErrorPage } from "./pages/ErrPage";

export const AppRouter = () => {
  const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const token = localStorage.getItem("AccessToken");
    const currentPath = location.pathname;

    const isTokenExpired = (token) => {
      if (!token) {
        return true;
      }

      const payloadBase64 = token.split(".")[1];
      const decodedPayload = JSON.parse(atob(payloadBase64));
      const exp = decodedPayload.exp;

      if (!exp) {
        return true;
      }

      const currentTime = Math.floor(Date.now() / 1000);
      return exp < currentTime;
    };
    const isAuthenticated = token && !isTokenExpired(token);

    if (!isAuthenticated) {
      if (currentPath.startsWith("/admin")) {
        return <Navigate to="/" state={{ from: location }} />;
      }
      if (currentPath.startsWith("/account")) {
        return <Navigate to="/" state={{ from: location }} />;
      }
    }

    return children;
  };
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/details/*" element={<DetailsPage />} />
      <Route path="/rules" element={<RulesPage />} />
      <Route path="/partners" element={<PartnersPage />} />
      <Route path="/contacts" element={<ContactPage />} />
      <Route
        path="/account/:name"
        element={<ProtectedRoute children={<AccountPage />} />}
      />
      <Route path="/aml" element={<AmlPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route
        path="/admin/:id"
        element={<ProtectedRoute children={<Admin />} />}
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
