import React from "react";
import { Header } from "../components/Header/Header";
import { Main } from "../components/Main/Main";
import "../index.css";
import { FeedBack } from "../components/FeedBack/FeedBack";
import { Footer } from "../components/Footer/Footer";
export const HomePage = () => {
  return (
    <>
      <div className="homePage_container">
        <Header />
        <Main />
      </div>
      <FeedBack />
      <Footer />
    </>
  );
};
