import React, { useContext } from "react";
import { Information } from "./ui/Information/Information";
import "./styles.css";
import { PaymentStep } from "./ui/PaymentStep/PaymentStep";
import { Context } from "../../App";
import { Requisits } from "./ui/Requisits/Requisits";

export const Details = () => {
  const { step, setStep } = useContext(Context);
  return (
    <div className="details_container">
      <Information />
      {!step ? <Requisits /> : <PaymentStep />}
    </div>
  );
};
