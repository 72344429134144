import React from "react";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { Contacts } from "../components/Contacts/Contacts";

export const ContactPage = () => {
  return (
    <>
      <Header />
      <Contacts />
      <Footer />
    </>
  );
};
