import React from "react";
import "./styles.css";
import { ReactComponent as Mail } from "../../images/svgs/email.svg";
import "./styles.css";
import { ReactComponent as Telegram } from "../../images/svgs/tg.svg";
import "./styles.css";

export const Contacts = () => {
  return (
    <div className="contacts_container">
      <div className="contacts_textHeader">Контакты</div>
      <div className="contacts_block">
        <div className="contact_main">
          <div className="contacts_textUp">Техническая поддержка</div>
          <div className="contacts_MainBlock">
            <div className="contacts_svg">
              <Mail />
            </div>
            <div
              className="contacts_text"
              onClick={() => (window.location = "mailto:kykyshka.pro@mail.ru")}
            >
              kykyshka.pro@mail.ru
            </div>
          </div>
          <div className="contacts_MainBlock">
            <div className="contacts_svg">
              <Telegram />
            </div>
            <div
              className="contacts_text"
              onClick={() => (window.location = "https://t.me/kykyshkapro")}
            >
              Telegram
            </div>
          </div>
          <div className="contacts_textDown">
            Если у вас возникли вопросы технического или финансового плана,
            напишите нам в онлайн чат или на почту, и мы поможем решить их!
          </div>
          <div className="contacts_buttonBlock">
            <div
              className="contacts_Button"
              onClick={() => (window.location = "mailto:kykyshka.pro@mail.ru")}
            >
              Отправить письмо
            </div>
            <div
              className="contacts_Button"
              onClick={() => window.jivo_api?.open()}
            >
              Написать в чат
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
