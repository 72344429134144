import React from "react";
import "./styles.css";
export const Footer = () => {
  return (
    <footer className="fullWidth_footer">
      <div className="footer_Container">
        <div className="footer_textBlock">
          <div className="footer_Text">
            Copyright© 2024, <span className="footer_logo">Kykyshka</span>
          </div>
          <div className="footer_Text1">Мультивалютный обменный сервис.</div>
          <div className="footer_Text1">
            Вы можете безопасно купить или продать нужную Вам валюту.
          </div>
        </div>
      </div>
    </footer>
  );
};
