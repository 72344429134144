import React, { useEffect, useState } from "react";
import "./styles.css";
import { getCourse } from "../../../api/admin/courseParse";
import SearchComponent from "../Search/Search";

export const Course = () => {
  const [courses, setCourses] = useState([]);
  const fetchCourse = async () => {
    try {
      const data = await getCourse();
      setCourses(data);
    } catch (error) {
      console.error("Error fetching data in useEffect:", error.message);
    }
  };
  useEffect(() => {
    fetchCourse();
  }, []);
  return (
    <div className="adminRight_Block">
      <div className="course_container">
        <div>Курсы из источников</div>
        <div className="payments_systemContainer">
          <div className="payments_Pay">Пара</div>
          <div className="payments_Pay">Код пары</div>
          <div className="payments_Pay">Источник</div>
          <div className="payments_Pay">Курс</div>
          <div className="payments_Pay">Статус</div>
        </div>
        <SearchComponent items={courses} />
      </div>
    </div>
  );
};
