import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  deleteDirection,
  getAllAdminDirections,
  getAllDirections,
  getDirection,
  postDirection,
  updateDirectionLine,
} from "../../../api/admin/exchangeDirection";
import { toast } from "react-toastify";
import { deletePaymentLine } from "../../../api/admin/paymentsList";
export const ExchangeDirection = () => {
  const [directions, setDirections] = useState([]);
  const [allExchanges, setAllExchanges] = useState([]);
  const [allDirections, setAllDirections] = useState([]);
  const [inputValues, setInputValues] = useState([]);

  const fetchDirections = async () => {
    try {
      const data = await getDirection();
      setDirections(data);
    } catch (error) {
      console.error("Error fetching data in useEffect:", error);
    }
  };
  useEffect(() => {
    fetchDirections();
  }, []);
  const fetchAllDirection = async () => {
    try {
      const data = await getAllAdminDirections();
      setAllDirections(data);
      setAllExchanges(
        data.map((dir) => {
          return dir.exchanges[0];
        })
      );
    } catch (error) {
      console.error("Error fetching data in useEffect:", error);
    }
  };
  useEffect(() => {
    fetchAllDirection();
  }, []);
  useEffect(() => {
    setInputValues(
      allDirections.map((payment) => ({
        profit: payment?.exchanges[0]?.profit || "",
        directionStatus: payment?.exchanges[0]?.status || "",
      }))
    );
  }, [allDirections]);

  const [directionOne, setDirectionOne] = useState(null);
  const [directionTwo, setDirectionTwo] = useState(null);
  const [profit, setProfit] = useState(null);

  const handleInputChange = (index, field, value) => {
    console.log(value);
    const newInputValues = [...inputValues];
    const originalValue = allExchanges[index][field];
    console.log(newInputValues);
    if (value === originalValue) {
      return;
    }

    newInputValues[index] = {
      ...newInputValues[index],
      [field]: value,
    };
    setInputValues(newInputValues);
  };

  const newDirection = () => {
    const data = {
      currencyFromId: directionOne,
      currencyToId: directionTwo,
      profit: profit,
    };
    postDirection(data).then(() => fetchAllDirection());
  };
  const handleDirectionChangeOne = (event) => {
    const selectedItem = directions.find(
      (item) => item.paymentSystem?.paymentName === event.target.value
    );
    setDirectionOne(selectedItem.id);
  };
  const handleDirectionChangeTwo = (event) => {
    const selectedItem = directions.find(
      (item) => item.paymentSystem?.paymentName === event.target.value
    );
    setDirectionTwo(selectedItem.id);
  };

  const handleSave = (index, id, profit) => {
    const dataToSave = {
      ...inputValues[index],
    };
    updateDirectionLine(id, dataToSave).then(() => {
      toast("Успешно");
      fetchAllDirection();
    });
  };
  const handleDelete = (id) => {
    deleteDirection(id).then(() => {
      toast("Успешно");
      fetchAllDirection();
    });
  };
  return (
    <div className="adminRight_Block">
      <div className="exchangeDirection_container">
        <div>Направление обмена</div>
        <div className="exchangeDir_MainBLock">
          <select className="select" onChange={handleDirectionChangeOne}>
            <option selected disabled>
              Откуда
            </option>
            {directions?.map((item) => (
              <option
                key={item.paymentSystem?.paymentName}
                value={item.paymentSystem?.paymentName}
              >
                {item.paymentSystem?.paymentName}
              </option>
            ))}
          </select>
          <select className="select" onChange={handleDirectionChangeTwo}>
            <option selected disabled>
              Куда
            </option>
            {directions?.map((item) => (
              <option
                key={item.paymentSystem?.paymentName}
                value={item.paymentSystem?.paymentName}
              >
                {item.paymentSystem?.paymentName}
              </option>
            ))}
          </select>
          <input
            onChange={(e) => setProfit(e.target.value)}
            className="exchange_inputPr"
            placeholder="Профит"
          />
          <div
            className="payments_button"
            onClick={() => {
              newDirection();
            }}
          >
            Добавить
          </div>
        </div>
        <div className="payments_systemContainer">
          <div className="payments_Pay">Направление</div>
          <div className="payments_Pay">Курс</div>
          <div className="payments_Pay">Курсы из источников</div>
          <div className="payments_Pay">Профит</div>
          <div className="payments_Pay">Статус</div>
          <div className="payments_Pay">-</div>
          <div className="payments_Pay">-</div>
        </div>
        {allDirections.map((dir, dirIndex) => {
          return dir.exchanges.map((childDir, childIndex) => (
            <div
              className="payments_systemContainer"
              key={`${dirIndex}-${childIndex}`}
            >
              <div className="payments_Pay">
                {childDir.technicalName || "Отсутствует"}
              </div>
              <div className="payments_Pay">
                {childDir.course || "Отсутствует"}
              </div>
              <div className="payments_Pay">{childDir.courseNumber || "-"}</div>
              <div className="payments_Pay">
                <input
                  className="payments_input"
                  value={inputValues[dirIndex]?.profit || ""}
                  onChange={(e) =>
                    handleInputChange(dirIndex, "profit", e.target.value)
                  }
                />
              </div>
              <div className="payments_Pay">
                <select
                  className="select"
                  onChange={(e) =>
                    handleInputChange(
                      dirIndex,
                      "directionStatus",
                      e.target.value
                    )
                  }
                >
                  <option>ACTIVE</option>
                  <option>NOT_ACTIVE</option>
                </select>
              </div>
              <div
                className="payments_PayButton"
                onClick={() =>
                  handleSave(
                    childIndex,
                    childDir?.id,
                    childDir?.profit,
                    childDir?.status
                  )
                }
              >
                сохранить
              </div>
              <div
                className="payments_PayButtonDelete"
                onClick={() => handleDelete(childDir?.id)}
              >
                удалить
              </div>
            </div>
          ));
        })}
      </div>
    </div>
  );
};
