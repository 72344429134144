// src/SearchComponent.js
import React, { useState } from "react";
import { updateCourse } from "../../../api/admin/courseParse";
import { getAllDirections } from "../../../api/admin/exchangeDirection";

const SearchComponent = ({ items }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = items.filter((item) =>
    item.currencyNaming.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCurrencyCodeChange = (event) => {
    const selectedItem = items.find(
      (item) => item.currencyNaming === event.target.value
    );
    if (selectedItem) {
      const data = {
        status:
          selectedItem.currencyNaming === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      };
      updateCourse(selectedItem.id, data);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Поиск..."
        value={searchTerm}
        onChange={handleChange}
      />
      <ul>
        {filteredItems.map((item) => (
          <div className="payments_systemContainer">
            <div className="payments_Pay">
              {item.currencyNaming || "Отсутствует"}
            </div>
            <div className="payments_Pay">-</div>
            <div className="payments_Pay">-</div>
            <div className="payments_Pay">
              {item.exchangeRate || "Отсутствует"}
            </div>
            <div className="payments_Pay"></div>
            <select
              onChange={handleCurrencyCodeChange}
              defaultValue={item?.courseStatus}
            >
              <option value={item.currencyNaming}>
                {item.courseStatus || "Отсутствует"}
              </option>
              <option>
                {item.currencyNaming === "ACTIVE" ? "INACTIVE" : "ACTIVE"}
              </option>
            </select>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default SearchComponent;
