import React from "react";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { Account } from "../components/Account";

export const AccountPage = () => {
  return (
    <>
      <Header />
      <Account />
      <Footer />
    </>
  );
};
