import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
  },
};

// Создание поля под валюту
export const newPaymetsList = async (data) => {
  try {
    const response = await axios.post(
      `https://server.kykyshka.pro/rest/currency/create`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

//Получение для options
export const getPaymentList = async () => {
  try {
    const response = await axios.get(
      `https://server.kykyshka.pro/rest/currency/create`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

//Получени списка
export const getPaymentAllList = async () => {
  try {
    const response = await axios.get(
      `https://server.kykyshka.pro/rest/currency/getAll`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

//Получени списка
export const updatePaymentLine = async (id, data) => {
  try {
    const response = await axios.post(
      `https://server.kykyshka.pro/rest/currency/update/${id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//Получени списка
export const deletePaymentLine = async (id) => {
  try {
    const response = await axios.delete(
      `https://server.kykyshka.pro/rest/currency/delete/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
