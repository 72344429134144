import React, { useState, useEffect } from "react";
import "./styles.css";

export const Loader = ({}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 0.5
      );
    }, 150); // Обновление каждые 150 мс для 30-секундного цикла

    return () => clearInterval(interval);
  }, []);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds === 1 ? 30 : prevSeconds - 1));
    }, 1000); // Обновление каждую секунду

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="progress-circle">
      <span className="progress-timer">{seconds}</span>
      <svg viewBox="0 0 36 36" className="circular-chart">
        <path
          className="circle-bg"
          d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${progress}, 100`}
          d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
    </div>
  );
};
