import React from "react";
import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const AccountMenu = () => {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <div className="accountMenu_container">
      <div
        className={`${
          params.name === "orders"
            ? "accountMenu_LinkActive"
            : "accountMenu_link"
        }`}
        onClick={() => navigate("/account/orders")}
      >
        Заявки
      </div>
      <div
        className={`${
          params.name === "" ? "accountMenu_LinkActive" : "accountMenu_link"
        }`}
        onClick={() => toast.error("Временно недоступно")}
      >
        Рефереальная программа
      </div>
      <div
        className={`${
          params.name === "" ? "accountMenu_LinkActive" : "accountMenu_link"
        }`}
        onClick={() => toast.error("Временно недоступно")}
      >
        Промокоды
      </div>
      <div
        className={`${
          params.name === "settings"
            ? "accountMenu_LinkActive"
            : "accountMenu_link"
        }`}
        onClick={() => navigate("/account/settings")}
      >
        Настройки
      </div>
      <div
        className={`${
          params.name === "" ? "accountMenu_LinkActive" : "accountMenu_link"
        }`}
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
      >
        Выход
      </div>
    </div>
  );
};
