import React, { useState } from "react";
import "./styles.css";

export const Aml = () => {
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const [stepFive, setStepFive] = useState(false);
  return (
    <div className="aml_container">
      <div className="aml_textHeader">AML/KYC</div>
      <div className="aml_block">
        <div className="aml_kycBlock" onClick={() => setStepOne(!stepOne)}>
          <div className="aml_kycBlockParent">
            <div>KYC-проверка: что это и где используется?</div>
            <div
              className={!stepOne ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepOne ? "→" : "↓"}
            </div>
          </div>
          {stepOne && (
            <div className="aml_kycBlockChild">
              Что такое KYC-процедура и почему ей уделяют так много внимания?
              Рассказываем, как она сможет уберечь вас от мошенников, сохранив
              анонимность. KYC- и AML-проверки: для чего нужна верификация
              личности в криптовалютной сфере. KYC (Know Your Customer) –
              процедура верификации личности клиента и оценки потенциальных
              рисков от него. Но зачем она нужна и почему сегодня практически
              невозможно купить криптовалюту, не подтвердив свою личность? Не
              противоречит ли это изначальным принципам анонимности и
              децентрализации криптоиндустрии? Сегодня мы разберем, для чего
              нужны AML- и KYC-проверки и как они работают. Также расскажем, как
              верификация поможет уменьшить количество мошенников, сохраняя при
              этом базовую анонимность пользователей.
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepTwo(!stepTwo)}>
          <div className="aml_kycBlockParent">
            <div>Что такое AML и для чего он нужен?</div>
            <div
              className={!stepTwo ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepTwo ? "→" : "↓"}
            </div>
          </div>
          {stepTwo && (
            <div className="aml_kycBlockChild">
              Anti-Money Laundering – комплекс мер по противодействию отмыванию
              средств, финансированию терроризма и созданию оружия массового
              уничтожения. Эта процедура включает в себя идентификацию, хранение
              и взаимный обмен информацией о клиентах, их прибыли и транзакциях
              между финансовыми организациями и государственными ведомствами.
              Большинство классических финансовых институтов используют AML-меры
              для проверки бизнеса, работающего с наличными или использующего
              нал как один из основных активов. Также они проверяют те
              предприятия, которые имеют деньги на разных счетах, регулярно
              переводят их в другие страны и банки, покупают фьючерсы и другие
              инструменты для наличного расчета. Другими словами, под
              верификацию попадают все бизнесы, которые потенциально могут
              обходить финансовый мониторинг и отмывать средства.
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepThree(!stepThree)}>
          <div className="aml_kycBlockParent">
            <div>Что показывает проверка адреса?</div>
            <div
              className={!stepThree ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepThree ? "→" : "↓"}
            </div>
          </div>
          {stepThree && (
            <div className="aml_kycBlockChild">
              Общий риск (в процентах) – вероятность того, что адрес связан с
              нелегальной деятельностью. Источники риска – известные типы
              сервисов, с которыми адрес взаимодействовал, и процент средств
              принятых с / отданных на эти сервисы, по которым рассчитывается
              общий риск.
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepFour(!stepFour)}>
          <div className="aml_kycBlockParent">
            <div>Почему сервисы настаивают на AML-процедурах?</div>
            <div
              className={!stepFour ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepFour ? "→" : "↓"}
            </div>
          </div>
          {stepFour && (
            <div className="aml_kycBlockChild">
              Если сервис не будет проводить подобные проверки, то мошенники
              могут использовать его как платформу для отмывания средств и
              финансирования терроризма. И тогда к ответственности привлекут сам
              сервис. Именно поэтому биржи и другие крупные криптовалютные
              компании имплементируют AML-требования в свой бизнес и проводят
              регулярные KYC-верификации.
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepFive(!stepFive)}>
          <div className="aml_kycBlockParent">
            <div>Как понимать оценку риска?</div>
            <div
              className={!stepFive ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepFive ? "→" : "↓"}
            </div>
          </div>
          {stepFive && (
            <div className="aml_kycBlockChild">
              <p>
                Если транзакция не прошла проверку средства будут заморожены
                биржей и возврат будет совершен в течении 5 дней.
              </p>
              <p>- 0−25% − это чистый кошелек/транзакция;</p>
              <p>- 25−75% − это средний уровень риска;</p>
              <p>- 75%+ − такой кошелек/транзакция считаются рискованной</p>
              Риск больше 50%, но я уверен, что адрес надежный. Как быть?
              Результаты проверки основаны на международных базах, которые
              постоянно обновляются. Поэтому адрес, который имел 0% риска вчера,
              сегодня мог получить или отдать актив рисковому контрагенту. В
              этом случае оценка риска изменится.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
