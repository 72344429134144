import React from "react";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { Error } from "../components/404/404";

export const ErrorPage = () => {
  return (
    <>
      <Header />
      <Error />
      <Footer />
    </>
  );
};
