import React, { useEffect, useRef } from "react";
import "./styles.css";
import { Exchange } from "./ui/Exchange";

export const Main = () => {
  const triangleRef = useRef(null);
  const squareRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      setTimeout(() => {
        if (triangleRef.current) {
          triangleRef.current.style.transform = `translateY(${
            scrollPosition * 0.5
          }px)`;
        }

        if (squareRef.current) {
          squareRef.current.style.transform = `translateY(${
            scrollPosition * 0.3
          }px)`;
        }
      }, 4000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="main_container">
      <div className="main_leftBlock">
        <div className="main_leftText">
          <div className="main_online">
            <div className="main_onlineCircle"></div>
            <span className="main_onlineText">Работаем 24/7</span>
          </div>
          <h1 className="main_mainText">
            Прозрачность и надежность в каждой транзакции
          </h1>
        </div>
      </div>
      <div className="main_rightBlock">
        <Exchange />
      </div>

      <div class="container">
        <div class="bird-container bird-container--one">
          <div class="bird bird--one"></div>
        </div>

        <div class="bird-container bird-container--two">
          <div class="bird bird--two"></div>
        </div>

        <div class="bird-container bird-container--three">
          <div class="bird bird--three"></div>
        </div>

        <div class="bird-container bird-container--four">
          <div class="bird bird--four"></div>
        </div>
      </div>
      <div ref={squareRef} className="shape square"></div>
      <div ref={squareRef} className="shape square1"></div>
      <div ref={squareRef} className="shape square2"></div>
    </div>
  );
};
