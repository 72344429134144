import React from "react";
import "./styles.css";

export const Rules = () => {
  return (
    <div className="rules_container">
      <div className="rules_textHeader">Правила обмена</div>
      <div className="rules_block">
        <div className="rules_textOne">
          Данная оферта содержит основные положения, условия и правила,
          основываясь на которых сервис kykyshka.pro предоставляет услуги своим
          клиентам. Прежде, чем воспользоваться услугами данного сервиса,
          пользователь должен согласиться со всеми изложенными ниже условиями.
          Только принимая все без исключения условия, пользователь может
          переходить к использованию сервиса. Эти правила обмена размещены на
          ресурсе kykyshka.pro в публичном доступе.
        </div>
        <div className="rules_BlockBottom">
          <div className="rules_leftBlock">
            <div className="rules_rulesParent">
              Правила и условия предоставления услуг сервисом
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.</span> Ключевые
              определения, понятия и термины
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.1</span> Сервис
              kykyshka.pro (далее - Сервис) – сервис, который предоставляет
              услуги физическим и юридическим лицам в части обмена, покупки либо
              продажи электронной валюты. Данные услуги осуществляются
              посредством специального программного комплекса, доступного по
              адресу https://kykyshka.pro.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.2</span> Пользователь –
              это юридическое / физическое лицо, которое безоговорочно приняло
              все условия данной оферты и пользуется услугами сервиса
              kykyshka.pro
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.3</span> Платежная
              система – созданный третьей стороной программный комплекс,
              посредством которого осуществляется учет цифровых денежных единиц,
              электронных денег и прочих обязательств, организовывается
              взаиморасчет пользователей и т.д. По данному соглашению, ключевыми
              платежными системами считаются: Яндекс.Деньги, BTC, Перфект Мани,
              QIWI, Payeer и т.д. Узнать полный перечень всех платежных систем
              юридические и физические лица всегда смогут на сайте kykyshka.pro.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.4</span> Платежная
              система – созданный третьей стороной программный комплекс,
              посредством которого осуществляется учет цифровых денежных единиц,
              электронных денег и прочих обязательств, организовывается
              взаиморасчет пользователей и т.д. По данному соглашению, ключевыми
              платежными системами считаются: Яндекс.Деньги, BTC, Перфект Мани,
              QIWI, Payeer и т.д. Узнать полный перечень всех платежных систем
              юридические и физические лица всегда смогут на сайте kykyshka.pro.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.5</span> Операции
              (платежи) – это осуществление перевода цифровой валюты
              (электронных денег) со счета отправителя на счет получателя.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.6</span> Заявка –
              конкретное намерение посетителя ресурса kykyshka.pro
              воспользоваться его услугами, приняв соответствующие правила
              данной оферты. Данное намерение выражается в форме предоставления
              пользователем информации и ее передачи сервису через специальную
              электронную форму, которая размещена на ресурсе kykyshka.pro
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.7</span> Исходная
              электронная валюта – это та валюта, которая имеется в наличии у
              пользователя, и которую он хочет обменять либо продать, получив
              взамен прочую электронную валюту или деньги в иной форме.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.8</span> Полученная
              валюта – это та валюта, которую пользователь сервиса kykyshka.pro
              получает в результате проведенного обмена, передавая исходную
              валюту сервиса.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.9</span> Стороны – это
              все пользователи, которые безоговорочно приняли указанные в данном
              соглашении правила и решили воспользоваться услугами сервиса
              kykyshka.pro, а также сам сервис kykyshka.pro
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.10</span> Обмен валюты –
              это совершение операции по обмену электронной валюты различных
              платежных систем.Полученная валюта – это та валюта, которую
              пользователь сервиса kykyshka.pro получает в результате
              проведенного обмена, передавая исходную валюту сервиса.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.11</span>Резервы валют –
              это количество электронных денег, которые сервис kykyshka.pro
              имеет в своем наличии, и в рамках которого пользователи могут
              осуществлять обменные операции посредством данного сервиса.
              Актуальную информацию о сумме резервов каждый пользователь может
              получить, посетив страницу "резервы" данного сервиса.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
          </div>
          <div className="rules_RightBlock">
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.1</span> Сервис
              kykyshka.pro (далее - Сервис) – сервис, который предоставляет
              услуги физическим и юридическим лицам в части обмена, покупки либо
              продажи электронной валюты. Данные услуги осуществляются
              посредством специального программного комплекса, доступного по
              адресу https://kykyshka.pro.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.12</span> Курс – это
              соотношение одной электронной валюты по отношению к другой в
              процессе совершения обмена.
            </div>
            <div className="rules_rulesText">
              <span className="rules_rulesTextNumber">1.10</span> Обмен валюты –
              это совершение операции по обмену электронной валюты различных
              платежных систем.Полученная валюта – это та валюта, которую
              пользователь сервиса kykyshka.pro получает в результате
              проведенного обмена, передавая исходную валюту сервиса.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
