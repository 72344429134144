import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
  },
};

export const getDirection = async () => {
  try {
    const response = await axios.get(
      `https://server.kykyshka.pro/rest/direction/create`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postDirection = async (data) => {
  try {
    const response = await axios.post(
      `https://server.kykyshka.pro/rest/direction/create`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getAllDirections = async () => {
  try {
    const response = await axios.get(
      `https://server.kykyshka.pro/direction/getAll`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getAllAdminDirections = async () => {
  try {
    const response = await axios.get(
      `https://server.kykyshka.pro/rest/direction/getAll`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const updateDirectionLine = async (id, data) => {
  try {
    const response = await axios.post(
      `https://server.kykyshka.pro/rest/direction/update/${id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const deleteDirection = async (id) => {
  try {
    const response = await axios.delete(
      `https://server.kykyshka.pro/rest/direction/delete/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
