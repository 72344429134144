import axios from "axios";

export const makeExchange = async () => {
  try {
    const response = await axios.get(
      `https://server.kykyshka.pro/direction/getAll`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
