import React, { useState } from "react";
import "./styles.css";

export const Faq = () => {
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const [stepFive, setStepFive] = useState(false);
  const [stepSix, setStepSix] = useState(false);
  return (
    <div className="aml_container">
      <div className="aml_textHeader">Часто задаваемые вопросы</div>
      <div className="aml_block">
        <div className="aml_kycBlock" onClick={() => setStepOne(!stepOne)}>
          <div className="aml_kycBlockParent">
            <div>Где я могу отследить статус моей заявки?</div>
            <div
              className={!stepOne ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepOne ? "→" : "↓"}
            </div>
          </div>
          {stepOne && (
            <div className="aml_kycBlockChild">
              Статус заявки можно отследить в Личном кабинете в пункте «заявки»
              ( логин и пароль от которого высылаться на Вашу почту, при
              регистрации, либо при первом создании заявки). Так же мы оповестим
              Вас, о статусе заявки, по почте.
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepTwo(!stepTwo)}>
          <div className="aml_kycBlockParent">
            <div>Для чего нужна верификация карты?</div>
            <div
              className={!stepTwo ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepTwo ? "→" : "↓"}
            </div>
          </div>
          {stepTwo && (
            <div className="aml_kycBlockChild">
              Верификация карты для того, чтобы предотвратить
              несанкционированное снятие средств с карты. В последнее время
              участились случаи взлома аккаунтов интернет банков, к сожалению
              злоумышленникам не составляет труда сделать дубликат сим карты и
              совершить перевод средств. В связи с этим, многие обменные
              сервисы, чтобы не быть вовлеченными в подобные мошеннические
              схемы, ввели процедуру верификации карты.
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepThree(!stepThree)}>
          <div className="aml_kycBlockParent">
            <div>Для чего нужны подтверждения в системе bitcoin?</div>
            <div
              className={!stepThree ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepThree ? "→" : "↓"}
            </div>
          </div>
          {stepThree && (
            <div className="aml_kycBlockChild">
              При получении bitcoin монет, получатель не может сразу ими
              воспользоваться. Как перевод совершен – он отправляется в сеть
              Bitcoin для исполнения и должен быть включен в блок, чтобы стать
              подтверждённым. Процесс включения перевода в состав найденного
              блока называется подтверждением транзакции. Включение в 1 блок = 1
              подтверждение, когда таких подтверждений набирается 2 и выше
              перевод считается подтвержденным, и операторы coinstart.cc, в свою
              очередь, обрабатывают заявку. Такой способ был введен для защиты
              от повторного пользования одних и тех же bitcoin.
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepFour(!stepFour)}>
          <div className="aml_kycBlockParent">
            <div>Дополнительные комиссии банков и платёжных систем.</div>
            <div
              className={!stepFour ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepFour ? "→" : "↓"}
            </div>
          </div>
          {stepFour && (
            <div className="aml_kycBlockChild">
              <p>
                Payeer: Комиссия (0.95%) в системе Payeer взимается с
                отправителя.
              </p>
              <p>
                Сбербанк Онлайн: Комиссия 1% в системе Сбербанк Онлайн взимается
                с отправителя.
              </p>
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepFive(!stepFive)}>
          <div className="aml_kycBlockParent">
            <div>Минимальная максимальная сумма обмена.</div>
            <div
              className={!stepFive ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepFive ? "→" : "↓"}
            </div>
          </div>
          {stepFive && (
            <div className="aml_kycBlockChild">
              <p>Минимальная сумма обмена</p>
              <p>RUB от 150 рублей USD от 3 долларов Bitcoin от 0.001 BTC</p>
              <p>Минимальная сумма отдачи</p>
              <p>ETH от 0.01 XRP от 20 BTC от 0.004</p>
              <p>
                Максимальная сумма обмена RUB до 200000 рублей USD до 3000
                долларов Bitcoin до 15 BTC
              </p>
            </div>
          )}
        </div>
        <div className="aml_kycBlock" onClick={() => setStepSix(!stepSix)}>
          <div className="aml_kycBlockParent">
            <div>Сколько времени обрабатывается заявка?</div>
            <div
              className={!stepSix ? "aml_clickBlock" : "aml_clickBlock green"}
            >
              {!stepSix ? "→" : "↓"}
            </div>
          </div>
          {stepSix && (
            <div className="aml_kycBlockChild">
              <p>
                Мы стараемся обрабатывать Ваши заявки как можно быстрее, время
                обработки в среднем занимает от 5-15 минут (средства могут
                зачисляться дольше), при условии если перевод совершается
                моментально.
              </p>
              <p>
                Бывают случаи задержек в самих платёжных системах, к сожалению
                мы не в силах повлиять на скорость таких транзакций.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
