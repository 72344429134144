import React from "react";
import { RightAdminMenu } from "./LeftBlock";
import { Applications } from "./RightBlock/Applications/Applications";
import "./index.css";
import { useParams } from "react-router-dom";
import { PaymentsSys } from "./PaymentsSys/PaymentsSys";
import { PaymentsCode } from "./PaymentCode/PaymentCode";
import { PaymentList } from "./PaymentList/PaymentList";
import { Course } from "./Course/Course";
import { ExchangeDirection } from "./ExchangeDirection/ExchangeDirection";
import { Requisites } from "./Requisites/Requisites";

export default function Admin() {
  const params = useParams();
  return (
    <div className="admin_Menu">
      <RightAdminMenu />
      {params.id === "1" && <PaymentsSys />}
      {params.id === "2" && <PaymentsCode />}
      {params.id === "3" && <PaymentList />}
      {params.id === "4" && <Course />}
      {params.id === "5" && <ExchangeDirection />}
      {params.id === "6" && <Applications />}
    </div>
  );
}
