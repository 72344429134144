import React, { useContext, useRef } from "react";
import "./styles.css";
import { Context } from "../../../../App";
import { ReactComponent as Copy } from "../../../../images/svgs/copy.svg";
import { toast } from "react-toastify";

export const PaymentStep = () => {
  const { selectedExchange, amount, uuidResult } = useContext(Context);
  const divRef = useRef(null);

  const copyTextFromDiv = () => {
    if (divRef.current) {
      const textToCopy = divRef.current.textContent.trim();

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast("Текст скопирован");
        })
        .catch((err) => {
          console.error("Не удалось скопировать текст: ", err);
        });
    }
  };
  return (
    <div className="payment_container">
      <div className="payment_headerBlock">Информация</div>
      <div className="payment_mainBlock">
        <div className="payment_info">
          Оплатите заявку{" "}
          <span className="payment_infoNumber">{uuidResult.payoutId}</span>
        </div>
        <div className="payment_mainCont">
          <div className="payment_reqBlock">
            <div className="payment_NumberCardText">Номер карты/счета</div>
            <div className="payment_copypay">
              <div className="payment_NumberCard" ref={divRef}>
                {selectedExchange?.requisiteGetCurrency ||
                  uuidResult.requisites ||
                  "Ничего нет"}
              </div>
              <div className="payment_copy" onClick={copyTextFromDiv}>
                <Copy />
              </div>
            </div>
          </div>
          {/* <div className="payment_reqBlock">
            <div className="payment_NumberCardText">ФИО</div>
            <div className="payment_NumberCard">{uuidResult.credentials}</div>
          </div> */}

          <div className="payment_reqBlock">
            <div className="payment_NumberCardText">Сумма к оплате</div>
            <div className="payment_NumberCard">{amount}</div>
          </div>
        </div>
        <div className="payment_infoBlock">
          <div className="payment_infoText">
            Обратите внимание - мы не принимаем платежи от юридических лиц.
            Заявки оплаченные от юр. лиц не будут исполнены. Так же, при оплате
            заявки после истечения срока, выданного на оплату, для подтверждения
            платежа может потребоваться верификация карты, с которой была
            произведена оплата. Переводы со счета сим карт и других платежных
            систем так же не будут засчитаны в пользу оплаты.
          </div>
          <div className="payment_infoText">
            Средства поступят в течение 24 часов. Статус заявки можете отследить
            через личный кабинет. По всем вопросам обращайтесь в техническую
            поддержку.
          </div>
        </div>
        <div className="payment_Button">Я оплатил</div>
      </div>
    </div>
  );
};
