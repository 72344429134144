import React from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";

export const Error = () => {
  const navigate = useNavigate();
  return (
    <div className="err_container">
      <div className="err_num">404</div>
      <div className="err_Text">Сожалеем, но такой страницы не существует.</div>
      <div className="err_Btn" onClick={() => navigate("/")}>
        Вернуться на главную
      </div>
    </div>
  );
};
