import React from "react";
import "./styles.css";
export const FeedBack = () => {
  return (
    <div className="fullWidth">
      <div className="feedBack_Container">
        <div className="feedBack_main">
          <div className="feedBack_text">
            Наши <span className="feedBack_textBlue">отзывы</span>
          </div>
          {/* <div className="feedBack_textleftText">Смотреть все отзывы</div> */}
        </div>
        <div className="feedBack_Block">
          <div className="feedBack_ChildBlock">Отзывов пока нет...</div>
        </div>
      </div>
    </div>
  );
};
