import axios from "axios";

export const getOrders = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
    },
  };

  try {
    const response = await axios.get(
      `https://server.kykyshka.pro/rest/payouts`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
