import React from "react";
import "./styles.css";

export const Partners = () => {
  return (
    <div className="partners_container">
      <div className="partners_textHeader">Партнерам</div>
      <div className="partners_block">В разработке...</div>
    </div>
  );
};
