import React, { createContext, useContext, useEffect, useState } from "react";
import { getAccount } from "../api/account";

// Создаем контекст
const AccountContext = createContext();

export const useAccount = () => useContext(AccountContext);

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const token = "your-token";
        const data = await getAccount(token);
        setAccount(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAccount();
  }, []);

  return (
    <AccountContext.Provider value={account}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
