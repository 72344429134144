import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import { Loader } from "../../../Main/ui/childrenUi/Loader/Loader";
import { Context } from "../../../../App";

export const Information = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const {
    selectedExchange,
    setSelectedExchange,
    setAmount,
    amount,
    uuidResult,
  } = useContext(Context);
  const formatExchangeRate = () => {
    if (selectedExchange) {
      return `1 ${
        selectedExchange?.currencyGet
      } = ${selectedExchange.courseNumber.toFixed(
        3
      )} ${selectedExchange.currencyReturn.split(" ").pop()}`;
    }

    return "Выберите обмен";
  };
  return (
    <div className="info_container">
      <div className="info_textBlock">
        <span>Информация об операции</span>
      </div>
      <div className="info_mainBlock">
        <div className="info_main">
          <span className="info_mainText">Отдаете</span>
          <div className="info_mainCourse">
            <span className="info_mainCourseText">{amount}</span>
            <span className="info_mainCourseName">
              {selectedExchange?.currencyGet || uuidResult.cryptoTicket}
            </span>
          </div>
        </div>
      </div>
      <div className="info_mainBlock">
        <div className="info_main">
          <span className="info_mainText">Получаете</span>
          <div className="info_mainCourse">
            <span className="info_mainCourseText">
              {amount * (selectedExchange?.courseNumber || uuidResult.course)}
            </span>
            <span className="info_mainCourseName">
              {selectedExchange?.currencyReturn || uuidResult.bankTicket}
            </span>
          </div>
        </div>
      </div>
      {!uuidResult && (
        <div className="exchange_courseBlock">
          <div className="exchange_courseLeftBlock">
            <Loader />
            <span className="exchange_courseText">Курс</span>
          </div>
          <div className="exchange_courseRightBlock">
            <span className="exchange_course">{formatExchangeRate()}</span>
          </div>
        </div>
      )}
      {uuidResult && (
        <>
          <div className="info_mainBlock">
            <div className="info_main">
              <span className="info_mainText">ФИО отправителя</span>
              <div className="info_mainCourse">
                <span className="info_mainCourseName">
                  {uuidResult.credentials}
                </span>
              </div>
            </div>
          </div>
          <div className="info_mainBlock">
            <div className="info_main">
              <span className="info_mainText">Номер карты</span>
              <div className="info_mainCourse">
                <span className="info_mainCourseName">
                  {uuidResult.cardNumber}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="info_mainBlock">
            <div className="info_main">
              <span className="info_mainText">Кошелек</span>
              <div className="info_mainCourse">
                <span className="info_mainCourseText">
                  {uuidResult.cardNumber}
                </span>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};
