import React, { useEffect, useState } from "react";
import "./styles.css";
import { getOrders } from "../../../api/orders";
import { useNavigate } from "react-router-dom";

export const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [stepOne, setStepOne] = useState(false);
  const navigate = useNavigate();
  const fetchOrders = async () => {
    try {
      const data = await getOrders();
      setOrders(data);
    } catch (error) {
      console.error("Error fetching data in useEffect:", error);
    }
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  function formatDate(isoString) {
    const date = new Date(isoString);

    // Опции для форматирования даты
    const options = {
      year: "numeric",
      month: "short", // Используйте 'short' для сокращенного названия месяца
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Локализованное форматирование даты
    return date.toLocaleDateString("ru-RU", options);
  }

  return (
    <div className="orders_container">
      {orders.length > 0 ? (
        orders.map((ord) => (
          <div
            className="order_block"
            onClick={() => navigate(`/details/${ord?.payoutId}`)}
          >
            <div className="order_statsBlcok">
              <div className="order_OrderMain">
                <div className="order">Заявка</div>
                <div className="order_status">{ord?.payoutStatus}</div>
                <div className="order_date">
                  {formatDate(ord?.creationDateTime)}
                </div>
              </div>
              <div className="order_childBlock">
                <div className="order_ChildName">
                  {ord?.cryptoAmount.toFixed(2)}
                </div>
                <div className="order_name">{ord?.cryptoTicket}</div>
              </div>
              <div className="order_childBlock">
                <div className="order_ChildName">{ord?.amount.toFixed(2)}</div>
                <div className="order_name">{ord?.bankTicket}</div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>Заявок нет...</div>
      )}
    </div>
  );
};
