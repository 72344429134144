import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  getCodePayment,
  newCodePayment,
} from "../../../api/admin/paymentsCode";
import axios from "axios";

export const PaymentsCode = () => {
  const [code, setCode] = useState(null);
  const [symbol, setSymbol] = useState(null);
  const [paymentCodes, setPaymentCodes] = useState([]);
  const data = {
    codeName: code,
    codeSymbol: symbol,
  };

  const fetchPaymentCode = async () => {
    try {
      const data = await getCodePayment();
      setPaymentCodes(data);
    } catch (error) {
      console.error("Error fetching data in useEffect:", error);
    }
  };
  useEffect(() => {
    fetchPaymentCode();
  }, []);

  return (
    <div className="adminRight_Block">
      <div className="adminRight_Block">
        <div className="payments_container">
          <div>Коды валют</div>
          <div className="payments_block">
            <div className="">
              <span>Код валюты</span>
              <input
                placeholder="Название пс"
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className="">
              <span>Символ валюты</span>
              <input
                placeholder="Символ валюты"
                onChange={(e) => setSymbol(e.target.value)}
              />
            </div>
            <div
              className="payments_button"
              onClick={() =>
                newCodePayment(data).then(() => fetchPaymentCode())
              }
            >
              Добавить
            </div>
          </div>

          <div className="payments_systemContainer">
            <div className="payments_Pay">Код валюты</div>
            <div className="payments_Pay">Символ</div>
            <div className="payments_Pay">Привязанные валюты</div>
            <div className="payments_Pay">Курс Обмена</div>
            <div className="payments_Pay">Курс из источника</div>
          </div>
          {paymentCodes.map((codes) => (
            <div className="payments_systemContainer">
              <div className="payments_Pay">
                {codes.currencyCode || "Отсутствует"}
              </div>
              <div className="payments_Pay">
                {codes.symbol || "Отсутствует"}
              </div>
              <div className="payments_Pay">{codes.currency || "-"}</div>
              <div className="payments_Pay">{"-"}</div>
              <div className="payments_Pay">{"-"}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
