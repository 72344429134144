import React from "react";
import { Header } from "../components/Header/Header";
import { Rules } from "../components/Rules/Rules";
import { Footer } from "../components/Footer/Footer";

export const RulesPage = () => {
  return (
    <>
      <Header />
      <Rules />
      <Footer />
    </>
  );
};
