import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
  },
};

export const getCourse = async () => {
  try {
    const response = await axios.get(
      `https://server.kykyshka.pro/rest/parsers/getAll`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const updateCourse = async (id, data) => {
  try {
    const response = await axios.post(
      `https://server.kykyshka.pro/rest/parsers/update/${id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
