import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import valid from "card-validator";
import { Context } from "../../../../App";
import { toast } from "react-toastify";
import axios from "axios";
import InputMask from "react-input-mask";
import { useLocation, useNavigate } from "react-router-dom";

export const Requisits = () => {
  const {
    selectedExchange,
    setSelectedExchange,
    setAmount,
    amount,
    setStep,
    setUuidResult,
    uuidResult,
  } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);
  const [email, setEmail] = useState("");
  const [card, setCard] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [credentials, setCredentials] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [cardError, setCardError] = useState(false);
  const [FIOError, setFIOError] = useState(false);

  function extractUUID(pathname) {
    const regex = /\/details\/([0-9a-fA-F-]{36})/;
    const match = pathname.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null; // Возвращаем null, если UUID не найден
    }
  }

  const validateCard = (card) => {
    const numberCard = valid.number(card);
    console.log(valid.number(card));
    return numberCard.isValid;
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateFullName = (fullName) => {
    const fullNameRegex = /^[A-Za-zА-Яа-яЁё\s]+$/;
    return fullNameRegex.test(fullName) && fullName.trim().length > 0;
  };

  useEffect(() => {
    const uuid = extractUUID(location.pathname);
    if (uuid?.length >= 32) {
      axios.get(`https://server.kykyshka.pro/payout/${uuid}`).then((res) => {
        setStep(true);
        setAmount(res.data.cryptoAmount);
        setUuidResult(res.data);
      });
    }
  }, []);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
    },
  };

  const sendApplication = () => {
    // Проверка email
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    // Проверка номера карты
    if (!validateCard(card)) {
      setCardError(true);
      return;
    } else {
      setCardError(false);
    }

    if (!validateFullName(credentials)) {
      setFIOError(true);
      return;
    } else {
      setFIOError(false);
    }
    if (selectedExchange?.payoutType === "CURRENCY_TO_CRYPTO") {
      // Если все проверки пройдены, продолжаем с отправкой данных
      const data = {
        bankTicket: selectedExchange?.currencyGet,
        amountMoneyCurrency: String(amount),
        cardNumber: card,
        cryptoTicket: selectedExchange?.currencyReturn,
        cryptoAmount: String(amount * selectedExchange?.courseNumber),
        walletAddress: walletAddress,
        email: email,
        course: String(selectedExchange?.courseNumber),
        requisites: selectedExchange?.requisiteGetCurrency,
      };
      if (localStorage.getItem("AccessToken")) {
        axios
          .post("https://server.kykyshka.pro/payout/rub", data, config)
          .then((res) => {
            setStep(true);
            toast.success("Успешно");
          })
          .catch((err) => toast.error("Ошибка: ", err.message));
      } else {
        axios
          .post("https://server.kykyshka.pro/payout/rub", data)
          .then((res) => {
            setStep(true);
            toast.success("Успешно");
          })
          .catch((err) => toast.error("Ошибка: ", err.message));
      }
    }

    if (selectedExchange?.payoutType === "CRYPTO_TO_CURRENCY") {
      const data = {
        cryptoTicket: selectedExchange?.currencyGet,
        amountCrypto: String(amount),
        cardNumber: card,
        bankTicket: selectedExchange?.currencyReturn,
        amountCurrency: String(amount * selectedExchange?.courseNumber),
        phoneNumberSBP: "123",
        credentials: credentials,
        email: email,
        course: String(selectedExchange?.courseNumber),
        requisites: selectedExchange?.requisiteGetCurrency,
      };
      if (localStorage.getItem("AccessToken")) {
        axios
          .post("https://server.kykyshka.pro/payout/crypto", data, config)
          .then((res) => {
            toast.success("Успешно");
            setStep(true);
            navigate(`/details/${res.data.payoutId}`);
          })
          .catch((err) => toast.error("Ошибка: ", err.message));
      } else {
        axios
          .post("https://server.kykyshka.pro/payout/crypto", data)
          .then((res) => {
            toast.success("Успешно");
            setStep(true);
            navigate(`/details/${res.data.payoutId}`);
          })
          .catch((err) => toast.error("Ошибка: ", err.message));
      }
    }
  };

  return (
    <div className="requisits_container">
      <div className="requisits_headerBlock">Информация</div>
      <div className="requisits_downBlock">
        <div className="requisits_downTextUp">Введите реквизиты</div>
        <div className="requisits_downInputBlock">
          {selectedExchange?.payoutType === "CURRENCY_TO_CRYPTO" ? (
            <>
              <div className="requisits_downInput">
                <div>Отправитель</div>
                <div
                  className={
                    !emailError
                      ? "requisits_InputChildBlock"
                      : "requisits_InputChildBlock err"
                  }
                >
                  {emailError && (
                    <div className="requisits_inputErr">
                      Введите корректный E-Mail
                    </div>
                  )}
                  <input
                    className="requisits_Input"
                    type="email"
                    placeholder="E-Mail"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div
                  className={
                    !FIOError
                      ? "requisits_InputChildBlock"
                      : "requisits_InputChildBlock err"
                  }
                >
                  {FIOError && (
                    <div className="requisits_inputErr">
                      Введите ФИО отправителя
                    </div>
                  )}
                  <input
                    className="requisits_Input"
                    placeholder="ФИО отправителя"
                    onChange={(e) => setCredentials(e.target.value)}
                  />
                </div>
                <div
                  className={
                    !cardError
                      ? "requisits_InputChildBlock"
                      : "requisits_InputChildBlock err"
                  }
                >
                  {cardError && (
                    <div className="requisits_inputErr">
                      Карта введена неверно
                    </div>
                  )}
                  <input
                    className="requisits_Input"
                    placeholder="Карта какая-то, от 16 до 19 цифр"
                    onChange={(e) => setCard(e.target.value)}
                  />
                </div>
              </div>
              <div className="requisits_downInput">
                <div>Получатель</div>
                <div className="requisits_InputChildBlock">
                  {/* <div className="requisits_inputErr">
                    Номер кошелька введен неверно
                  </div> */}
                  <input
                    className="requisits_Input"
                    placeholder="Номер кошелька"
                    onChange={(e) => setWalletAddress(e.target.value)}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="requisits_downInput">
                <div>Отправитель</div>

                <div
                  className={
                    !emailError
                      ? "requisits_InputChildBlock"
                      : "requisits_InputChildBlock err"
                  }
                >
                  {emailError && (
                    <div className="requisits_inputErr">
                      Введите корректный E-Mail
                    </div>
                  )}
                  <input
                    className="requisits_Input"
                    type="email"
                    placeholder="E-Mail"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="requisits_downInput">
                <div>Получатель</div>
                <div
                  className={
                    !FIOError
                      ? "requisits_InputChildBlock"
                      : "requisits_InputChildBlock err"
                  }
                >
                  {FIOError && (
                    <div className="requisits_inputErr">
                      Введите ФИО отправителя
                    </div>
                  )}
                  <input
                    className="requisits_Input"
                    placeholder="ФИО Получателя"
                    onChange={(e) => setCredentials(e.target.value)}
                  />
                </div>
                <div
                  className={
                    !cardError
                      ? "requisits_InputChildBlock"
                      : "requisits_InputChildBlock err"
                  }
                >
                  {cardError && (
                    <div className="requisits_inputErr">
                      Карта введена неверно
                    </div>
                  )}

                  <InputMask
                    className="requisits_Input"
                    placeholder="Карта Сбербанка, от 16 до 19 цифр"
                    mask="9999 9999 9999 9999 999"
                    maskChar=""
                    onChange={(e) => setCard(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="requisits_policy">Тут соглашение</div>
        <div className="exchange_button" onClick={() => sendApplication()}>
          <span className="exchange_buttonText">Начать транзакцию</span>
        </div>
      </div>
    </div>
  );
};
